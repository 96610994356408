* {
    margin: 0;
}

.editors-container {
    display: flex;
    height: 50vh;
    background-color: gray;
}

.editors {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    padding: 0.4rem;
}

.CodeMirror {
    height: 100% !important;
    background-color: rgb(28, 36, 46) !important;
}

.title {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 0.6rem 0.6rem 0.8rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.title span {
    font-size: 1rem;
}

.edior-All {
    flex-grow: 1;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.shrink {
    flex-grow: 0;
}

.shrink .CodeMirror-scroll {
    position: absolute;
    overflow: hidden !important;
}

.web-dom h5 {
    padding-top: 2px;
    text-align: center;
}

iframe {
    border: none;
}

.grow-btn {
    border: none;
    background: none;
    color: white;
    cursor: pointer;
}